<template>
  <div class="tabs">
    <div
      :class="['tabs__background', alternativeStyle ? 'tabs__background_alternative' : '']"
      :style="{
        width: backgroundWidth + 'px',
        height: alternativeStyle ? 2 + 'px' : backgroundHeight - 2 + 'px',
        left: backgroundPosition + 'px'
      }"
    ></div>
    <div :class="['tabs__items', alternativeStyle ? 'tabs__items_alternative' : '']">
      <div
        ref="tab"
        @click="updateTab(idx)"
        v-for="(tab, idx) in tabs"
        :key="`tab_${idx}`"
        :class="[
          'tabs__item',
          idx === activeTab && alternativeStyle
            ? 'tabs__item_active-alternative'
            : idx === activeTab
            ? 'tabs__item_active'
            : '',
          alternativeStyle ? 'tabs__item_alternative' : ''
        ]"
      >
        {{ tab }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TabsWidget",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    label: String,
    defaultActiveTab: {
      type: Number,
      default: () => {
        return null;
      }
    },
    alternativeStyle: {
      type: Boolean,
      default: false
    },
    unique: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      backgroundWidth: "",
      backgroundHeight: "",
      backgroundPosition: "",
      activeTab: 0
    };
  },
  mounted() {
    if (this.defaultActiveTab) {
      this.activeTab = this.defaultActiveTab;
    }
    this.backgroundWidth = this.$refs.tab[this.activeTab].clientWidth;
    this.backgroundHeight = this.$refs.tab[this.activeTab].clientHeight;
    this.backgroundPosition = this.$refs.tab[this.activeTab].offsetLeft;
  },
  methods: {
    ...mapActions("NewsModule", ["changeTab"]),
    updateTab(i) {
      this.activeTab = i;
      this.backgroundWidth = this.$refs.tab[i].clientWidth;
      this.backgroundHeight = this.$refs.tab[i].clientHeight;
      this.backgroundPosition = this.$refs.tab[i].offsetLeft;
      this.$emit("update:tab_item", this.tabs[this.activeTab]);
      this.$emit("update:tab_index", this.activeTab);
      this.changeTab(this.tabs[this.activeTab]);
      this.$root.$emit(`tab:update_${this.unique}`, this.tabs[this.activeTab]);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  width: 100% !important;
  height: 1px !important;
}

::-webkit-scrollbar-track {
  background-color: rgba(141, 150, 236, 0.1);
  width: 100% !important;
  height: 1px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(141, 150, 236, 0.5);
  border-radius: 5px !important;
  width: 1px !important;
  height: 1px !important;
}

.tabs {
  position: relative;
  border-bottom: 1px solid #e2e8f0;
  margin-right: 30px;
  width: 100%;
  @media screen and (max-width: $xs) {
    padding-right: 13px;
    padding-left: 13px;
    margin-right: 0;
  }
  &__background {
    position: absolute;
    top: 3.5%;

    background-color: $color-interactive;
    transition: all 0.3s ease;
    border-radius: 4px;
    z-index: 2;

    &_alternative {
      bottom: 0;
      top: auto;
      @media screen and (max-width: $xs) {
        margin-right: 13px;
        margin-left: 13px;
      }
      background-color: $action-primary;
      border-radius: 0;
    }
  }

  &__items {
    width: max-content;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $color-elements-background;
    border-radius: 4px;

    &_alternative {
      background-color: transparent;
      @media screen and (max-width: 1280px) {
        justify-content: space-between;
        width: 100%;
      }
      @media screen and (max-width: $lg) {
        justify-content: flex-start;
      }
      @media screen and (max-width: 767px) {
        justify-content: space-between;
      }
    }
  }

  &__item {
    position: relative;
    padding: 6px 38px;
    z-index: 3;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: $action-primary;

    cursor: pointer;
    transition: all 0.3s ease;

    &:first-child {
      margin-left: 1px;
    }

    &:last-child {
      margin-right: 1px;
    }

    &_active {
      color: $color-background;
    }

    &_alternative {
      padding: 0 0 16px;
      margin-left: 56px;
      @media screen and (max-width: 1124px) {
        margin-left: 0;
      }
      @media screen and (max-width: $lg) {
        font-size: 13px;
        margin-left: 39px;
      }
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
      @media screen and (max-width: 468px) {
        font-size: 11px;
      }
      color: #718096;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #515168;
      }
    }

    &_active-alternative {
      color: $action-primary;

      &:hover {
        color: $action-primary;
      }
    }
  }
}
</style>
